import React from 'react';
import './style.css';
import image11 from 'images/image 11.png';
import image12 from 'images/image 12.png';
import image13 from 'images/image 13.png';
import image14 from 'images/image 14.png';
import image15 from 'images/image 15.png';
import image16 from 'images/image 16.png';
import image17 from 'images/image 17.png';
import image18 from 'images/image 18.png';
import image19 from 'images/image 19.png';
import amigoPng from 'images/amigo.png';
import epayPng from 'images/epay.png';
import vietinPng from 'images/vietinbank.png';

function Over500() {
  const dataParameter = [
    {
      title: '2,541+',
      content: 'Compellingly engage corporate scenarios for monetize.',
    },
    {
      title: '154.0',
      content: 'Conveniently target enterprise vortals for process-centric. ',
    },
    {
      title: '15M+',
      content: 'Compellingly engage corporate scenarios for monetize.',
    },
    {
      title: '95%',
      content: 'Conveniently target enterprise vortals for process-centric. ',
    },
  ];
  return (
    <div className="container xl:px-[50px] mx-auto  my-10 md:flex ">
      <div className="md:w-full text-center md:text-left">
        <p className="text-[28px] font-bold my-[10px]">Powerful Solutions for Your Business Needs</p>
        <p className="text-[14px]  italic">Quickly incubate functional channels with multidisciplinary architectures </p>
        <div className="flex flex-wrap my-5 justify-center md:justify-start ">
          {dataParameter.map((item) => (
            <div
              key={item.title}
              className="w-[310px] h-[160px] px-[20px] py-[25px] shadow rounded-tl-2xl rounded-br-xl mx-[15px] my-[20px] flex flex-col hover:shadow-xl hover:scale-105 transition-all">
              <p className="text-[34px] text-amber-300 font-bold mx-auto mb-[9px] ">{item.title}</p>
              <p className="text-[14px] text-center italic text-slate-500  ">{item.content}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Over500;
