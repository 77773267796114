import Button from 'components/button';
import MenuBar from 'components/menu-bar';
import { navigate } from 'gatsby';
import CompanyImage from 'images/skyline-company.png';
import './style.css';
import React from 'react';

function HeaderHome() {
  const onHandleApply = () => {
    navigate('/career/', { state: { name: 'career' } });
  };
  return (
    <div className="bg-home  h-[610px] bg-no-repeat bg-cover bg-center overflow-hidden relative mt-[80px]">
      <div className="absolute top-[33px] left-1/2 -translate-x-1/2 transform"></div>
      <div className="container mx-auto xl:px-[50px] h-full overflow-hidden ">
        <div className="flex items-center justify-between h-full">
          <div className="md:w-5/12">
            <p className="text-[30px] font-bold text-[#373737] uppercase">we are</p>
            <p className="text-[48px]  md:text-[66px] leading-[50px] md:leading-[70px] font-[1000] my-2 text-[#373737] tracking-[7px] md:tracking-[18px]">SKYLINETECH</p>
            <p className="text-[22px] leading-6 font-bold my-1">#1 Internet Service Provider Company </p>
            <p className="text-[15px] text-[#363636]">
              {/* We guarantee high-quality customer support service with high-speed satellite connection all over the world.  */}
              We guarantee high-quality customer support service with high-speed satellite connection all over the world. Enjoy fun family nights and loud friends parties
              at your place!
            </p>
          </div>
          <div className="lg:flex items-center h-auto md:w-5/12 relative hidden">
            <img src={CompanyImage} alt="company" className="w-full h-full object-cover rounded-xl" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderHome;
