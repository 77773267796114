import React from 'react';
import './style.css';
import { Link } from 'gatsby';
import box from 'images/box-1.png';
import star from 'images/star-1.png';
import layout1 from 'images/layout-1.png';
import browser2 from 'images/browser-2.png';
import passport from 'images/passport1.png';
import desert from 'images/desert1.png';

function SoluAndServi() {
  const dataSolu = [
    {
      icon: desert,
      title: 'Payment intermediary service',
      content: 'Intermediate payment system for individuals and businesses.',
    },
    {
      icon: box,
      title: 'Financial trading platform',
      content: 'Commodity derivative management and trading system. Personal Loan.',
    },
    {
      icon: star,
      title: 'Software solution consulting',
      content: 'Comprehensive solution for companies digital transformation needs.',
    },
    {
      icon: layout1,
      title: 'Public service',
      content: 'Implementation services for government agencies.',
    },
    {
      icon: browser2,
      title: 'Build a website',
      content: 'SEO standard website for businesses.',
    },
    {
      icon: passport,
      title: 'Mobile application ',
      content: 'Mobile apps and games.',
    },
  ];

  return (
    <div className="bg-solu  bg-no-repeat bg-cover bg-center overflow-hidden relative">
      <div className="flex flex-col my-[130px] justify-center text-center">
        <p className="mx-auto mt-10 text-[42px] font-bold">Solutions & Services</p>
        <div className="mx-auto my-3 w-[175px] h-[3px] bg-gradient-to-r from-cyan-500 to-teal-500"></div>
        <div className="mx-auto w-1/2 flex justify-center  ">
          <p className="text-center text-[15px] italic text-slate-500">
            We provide quality services with people with many years of experience in the fields of finance, banking, electronic transactions, and software solutions..{' '}
          </p>
        </div>
        <div className="container xl:px-[50px] mx-auto flex flex-wrap justify-center my-10 ">
          {dataSolu.map((item) => (
            <div
              key={item.title}
              className=" w-[424px] min-h-[150px] px-[20px] py-[20px]  bg-white rounded-lg mx-3 my-3 shadow hover:shadow-xl hover:scale-105 transition-all">
              <Link to="/service" state={{ name: 'service' }}>
                <div className="flex items-center mb-[20px]">
                  <img src={item.icon} alt="" />
                  <p className="ml-[20px] text-[16px] font-bold bg-clip-text text-transparent bg-gradient-to-r from-cyan-500 to-teal-500">{item.title}</p>
                </div>
                <p className="text-[15px]">{item.content}</p>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default SoluAndServi;
