import React from 'react';
import './style.css';
import { Link, navigate } from 'gatsby';
import wcs from 'images/wcs.png';
import iconExpert from 'images/iconExpert.png';
import iconPractive from 'images/iconPractive.png';
function WhyCS() {
  const handleClick = () => {
    navigate('/about/', { state: { name: 'about' } });
  };
  return (
    <div className="container xl:px-[50px] md:flex md:justify-between w-full  mx-auto mt-3 py-10 ">
      <div className="md:w-5/12">
        <p className="text-[42px] font-bold text-center md:text-left">Why Choose Us</p>
        <img src={wcs} alt="" className="mt-[42px]" />
      </div>
      <div className="md:w-6/12 ">
        <div className="text-center md:text-left">
          <p className="text-[22px] font-extrabold my-3">We are working with 15 years exprience </p>
          <p className="text-[15px] italic text-slate-500">
            We provide quality services with people with many years of experience in the fields of finance, banking, electronic transactions, and software solutions.
          </p>
        </div>
        <div className="flex mt-[35px] flex-wrap justify-center md:flex-nowrap">
          <div className="w-2/3 md:w-1/2 md:mr-[70px] mr-[30px] my-[20px]">
            <div className="md:flex items-center mb-[35px] text-center md:text-left ">
              <img src={iconExpert} alt="" className="" />
              <p className="text-[19px] font-bold ml-[10px]">Multifunction Specialists</p>
            </div>
            <div className="text-center md:text-left">
              <p className="text-[16px] font-bold mb-[10px] bg-clip-text text-transparent bg-gradient-to-r from-cyan-500 to-teal-500">Banking and finance</p>
              <p className="text-[15px] mb-[30px]">Skyline Tech's senior personnel have been holding important positions in banks in Vietnam.</p>
              <p className="text-[16px] font-bold my-[10px] bg-clip-text text-transparent bg-gradient-to-r from-cyan-500 to-teal-500">Payment services</p>
              <p className="text-[15px] mb-[30px]">We have experience with the design and operation of interbank payment systems in Vietnam and abroad.</p>
              <p className="text-[16px] font-bold my-[10px] bg-clip-text text-transparent bg-gradient-to-r from-cyan-500 to-teal-500">Information technology</p>
              <p className="text-[15px] ">
                Skyline Tech experts have qualifications and experience in designing and operating systems that carry very large loads and require high safety.
              </p>
            </div>
          </div>
          <div className="w-2/3 md:w-1/2 my-[20px] ">
            <div className="md:flex items-center mb-[35px] text-center md:text-left">
              <img src={iconPractive} alt="" className="" />
              <p className="text-[19px] font-bold ml-[10px]">Best Practice For Business</p>
            </div>
            <div className="text-center md:text-left">
              <p className="text-[16px] font-bold mb-[10px] bg-clip-text text-transparent bg-gradient-to-r from-cyan-500 to-teal-500">Consulting</p>
              <p className="text-[15px] mb-[30px]">We will provide solutions for your business in its digital transformation needs</p>
              <p className="text-[16px] font-bold my-[10px] bg-clip-text text-transparent bg-gradient-to-r from-cyan-500 to-teal-500">System construction</p>
              <p className="text-[15px] mb-[30px]">Skyline will develop the software system according to the plan discussed with the business</p>
              <p className="text-[16px] font-bold my-[10px] bg-clip-text text-transparent bg-gradient-to-r from-cyan-500 to-teal-500">Transfer/Operation</p>
              <p className="text-[15px] ">
                Skyline participates in training, transferring software systems to businesses, or participating in operations according to each business's needs.
              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-center md:justify-start">
          <button
            onClick={handleClick}
            className="w-[200px] h-[48px] mt-[24px] rounded-full bg-gradient-to-r from-cyan-500 to-teal-500 shadow hover:shadow-xl text-white font-semibold text-[16px] ">
            More
          </button>
        </div>
      </div>
    </div>
  );
}

export default WhyCS;
