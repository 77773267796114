import React from 'react';
import './style.css';
import TopupBilling from 'images/TopupBilling.png';
import HangHoa247 from 'images/HangHoa247.png';
import ThueHaiQuan from 'images/ThueHaiQuan.png';
import VniId from 'images/VniId.png';
import EduBills from 'images/EduBills.png';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import { Carousel } from 'antd';
function Portfolio() {
  return (
    <div className=" flex flex-col  justify-center text-center bg-[#F8F9FA] pb-[40px]">
      <div className=" flex flex-col  justify-center text-center bg-[#F8F9FA]">
        <p className="mx-auto mt-10 text-[42px] font-bold">Portfolio</p>
        <div className="mx-auto my-3 w-[175px] h-[3px] bg-gradient-to-r from-cyan-500 to-teal-500"></div>
      </div>
      <Carousel autoplay dots infinite>
        <div className="flex flex-wrap justify-center w-full  bg-[#F8F9FA] h-[610px]  ">
          <div className="flex flex-row justify-center w-full">
            <div className="w-5/10 mt-[40px] flex justify-end">
              <img className=" my-auto max-w-full object-contain " src={TopupBilling} alt={`Img_TopupBilling`} />
            </div>
            <div className="w-1/6 mt-[40px] flex flex-col justify-end align-bottom text-right ml-[100px]">
              <p className="text-[34px] font-bold text-[#43BFC0] mx-[16px]">TOPUP BILLING</p>
              <p className="text-[15px] ]">Application to recharge phone and pay utility bills online.</p>
            </div>
          </div>
        </div>
        <div>
          <div className="flex flex-row justify-center w-full">
            <div className="w-5/10 mt-[40px] flex justify-end">
              <img className=" my-auto max-w-full object-contain " src={HangHoa247} alt={`Img_TopupBilling`} />
            </div>
            <div className="w-1/6 mt-[40px] flex flex-col justify-end align-bottom text-right ml-[100px]">
              <p className="text-[34px] font-bold text-[#43BFC0] mx-[16px]">HÀNG HÓA 247</p>
              <p className="text-[15px] ]">Vietnam's No. 1 platform for commodity derivatives trading.</p>
            </div>
          </div>
        </div>
        <div>
          <div className="flex flex-row justify-center w-full">
            <div className="w-5/10 mt-[40px] flex justify-end">
              <img className=" my-auto max-w-full object-contain " src={EduBills} alt={`Img_TopupBilling`} />
            </div>
            <div className="w-1/6 mt-[40px] flex flex-col justify-end align-bottom text-right ml-[100px]">
              <p className="text-[34px] font-bold text-[#43BFC0] mx-[16px]">EDUBILLS</p>
              <p className="text-[15px] ]">
                The solution provides payment gateway services and online tuition lookup for students, allowing students to flexibly choose payment methods for
                receivables.
              </p>
            </div>
          </div>
        </div>
        <div>
          <div className="flex flex-row justify-center w-full">
            <div className="w-5/10 mt-[40px] flex justify-end">
              <img className=" my-auto max-w-full object-contain " src={ThueHaiQuan} alt={`Img_TopupBilling`} />
            </div>
            <div className="w-1/6 mt-[40px] flex flex-col justify-end align-bottom text-right ml-[100px]">
              <p className="text-[34px] font-bold text-[#43BFC0] mx-[16px]">THUẾ HẢI QUAN</p>
              <p className="text-[15px] ]">Tax lookup and payment platform.</p>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap justify-center w-full  bg-[#F8F9FA] h-[610px]  ">
          <div className="flex flex-row justify-center w-full">
            <div className="w-5/10 mt-[40px] flex justify-end">
              <img className=" my-auto max-w-full object-contain " src={VniId} alt={`Img_TopupBilling`} />
            </div>
            <div className="w-1/6 mt-[40px] flex flex-col justify-end align-bottom text-right ml-[100px]">
              <p className="text-[34px] font-bold text-[#43BFC0] mx-[16px]">VNEID</p>
              <p className="text-[15px] ]">Electronic identification application, integrating all people's personal documents on a digital platform.</p>
            </div>
          </div>
        </div>
      </Carousel>
    </div>
  );
}

export default Portfolio;
