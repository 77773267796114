import HeaderHome from 'components/contentHome/headerHome';
import Over500 from 'components/contentHome/Over500';
import Portfolio from 'components/contentHome/Portfolio';
import SoluAndServi from 'components/contentHome/SoluAndServi';
import WhyCS from 'components/contentHome/WhyCS';
import Powerful from 'components/contentHome/Powerful';
import Layout from 'components/layout';
import Seo from 'components/seo';
import React from 'react';

export default function Home(props: any) {
  console.log({ props });
  return (
    <div>
      <Seo title="Skyline Tech" />
      <Layout>
        <HeaderHome />
        <WhyCS />
        <SoluAndServi />
        <Powerful />
        <Portfolio />
        <Over500 />
      </Layout>
    </div>
  );
}
