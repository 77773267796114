import React from 'react';
import './style.css';
import image11 from 'images/image 11.png';
import image12 from 'images/image 12.png';
import image13 from 'images/image 13.png';
import image14 from 'images/image 14.png';
import image15 from 'images/image 15.png';
import image16 from 'images/image 16.png';
import image17 from 'images/image 17.png';
import image18 from 'images/image 18.png';
import image19 from 'images/image 19.png';
import amigoPng from 'images/amigo.png';
import epayPng from 'images/epay.png';
import vietinPng from 'images/vietinbank.png';

function Over500() {
  const dataPartner = [
    {
      logo: image11,
    },
    {
      logo: image12,
    },
    {
      logo: image13,
    },
    {
      logo: image14,
    },
    {
      logo: image15,
    },
    {
      logo: image16,
    },
    {
      logo: image17,
    },
    {
      logo: image18,
    },
    {
      logo: image19,
    },
    {
      logo: amigoPng,
    },
    {
      logo: epayPng,
    },
    {
      logo: vietinPng,
    },
  ];
  const dataParameter = [
    {
      title: '2,541+',
      content: 'Compellingly engage corporate scenarios for monetize.',
    },
    {
      title: '154.0',
      content: 'Conveniently target enterprise vortals for process-centric. ',
    },
    {
      title: '15M+',
      content: 'Compellingly engage corporate scenarios for monetize.',
    },
    {
      title: '95%',
      content: 'Conveniently target enterprise vortals for process-centric. ',
    },
  ];
  return (
    <div className="container xl:px-[50px] mx-auto  my-12 md:flex ">
      <div className="md:w-full text-center md:text-left">
        <p className="text-[28px] font-bold my-[10px]">Our partners</p>
        <p className="text-[14px]  italic">Quickly incubate functional channels with multidisciplinary architectures </p>
        <div className="flex flex-wrap my-5 justify-center md:justify-start">
          {dataPartner.map((item, index) => (
            <div
              key={item.logo}
              className="w-[195px] h-[100px]  shadow rounded-tl-xl rounded-br-xl mx-[15px] mb-[30px] hover:shadow-xl hover:scale-105 flex transition-all overflow-hidden">
              <img className="mx-auto my-auto max-w-full object-contain h-[80px] w-[100px]" src={item.logo} alt={`logo_${index}`} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Over500;
